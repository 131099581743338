import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';

import {
  Container,
  FlexWrapper,
  H2,
  Regular,
  Small,
  Svg,
  TextWrapper,
  Tiny,
} from 'components';
import { mobile, useQuery } from 'styles/breakpoints';
import { validateEmail } from 'utils/validations';
import { almostWhite, blue, blue10, blue30, blue50, text } from 'styles/colors';
import { useDispatch } from 'react-redux';
import { createLead, updateEmail } from 'state/user/effects';

const SOCIAL_NETWORK_ICON_PROPS = {
  width: '30',
  height: '30',
  viewBox: '0 0 30 30',
  fill: 'none',
};

const FooterDefault: React.FC<RouteComponentProps> = React.memo(() => {
  const dispatch = useDispatch();
  const { isMobile } = useQuery();
  const [email, setEmail] = React.useState<string>('');
  const [emailSent, setEmailSent] = React.useState<boolean>(false);
  const [sendingEmail, setSendingEmail] = React.useState<boolean>(false);

  const handleSubmit = async () => {
    if (validateEmail(email)) {
      setSendingEmail(true);
      await dispatch(updateEmail(email));
      await dispatch(createLead({ email, funnel: 'main', quiz_answers: {} }));

      setTimeout(() => {
        setEmailSent(true);
      }, 1000);
    }
  };

  return (
    <FooterWrapper>
      <Container>
        <ContentWrapper
          alignItems="center"
          justifyContent="space-between"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <div>
            <TextWrapper maxWidth={isMobile ? '18.375rem' : '30rem'}>
              <H2 color={blue}>
                Get monthly practical advice right to your email
              </H2>
            </TextWrapper>
            {emailSent ? (
              <Regular color={blue} margin="2rem 0 3.875rem">
                Thank you!
              </Regular>
            ) : (
              <InputWrapper>
                <Input
                  type="email"
                  name="email"
                  placeholder="Your Email Address"
                  disabled={sendingEmail}
                  className={sendingEmail ? 'disabled' : ''}
                  value={email}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                  onChange={event => setEmail(event.target.value)}
                />
                <ArrowRightIcon
                  src="icons/arrow_right"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                />
              </InputWrapper>
            )}
            <SocialLinksWrapper
              alignItems="center"
              justifyContent="space-between"
            >
              <a
                href="https://www.facebook.com/Pulsio-727153317985278"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Svg
                  src="images/footer/facebook"
                  {...SOCIAL_NETWORK_ICON_PROPS}
                />
              </a>

              <Svg
                src="images/footer/instagram"
                {...SOCIAL_NETWORK_ICON_PROPS}
              />

              <Svg
                src="images/footer/linkedin"
                {...SOCIAL_NETWORK_ICON_PROPS}
              />

              <Svg src="images/footer/twitter" {...SOCIAL_NETWORK_ICON_PROPS} />
            </SocialLinksWrapper>
          </div>
          <LinksWrapper>
            <StyledLink to="/quiz">
              <Small color={blue}>TAKE THE QUIZ</Small>
            </StyledLink>
            <StyledLink to="/faq">
              <Small color={blue}>FAQ</Small>
            </StyledLink>
            <StyledLink to="/contacts">
              <Small color={blue}>CONTACT</Small>
            </StyledLink>
          </LinksWrapper>
        </ContentWrapper>
        <FlexWrapper
          alignItems={isMobile ? 'flex-start' : 'center'}
          justifyContent="space-between"
        >
          <YearText color={blue50} fontSize="0.75rem">
            @ {new Date().getFullYear()} Pulsio
          </YearText>
          <FlexWrapper
            justifyContent="flex-end"
            alignItems={isMobile ? 'flex-end' : 'center'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <PoliciesLink to="/privacy-policy">
              <Tiny color={blue50} fontSize="0.75rem">
                Privacy Policy
              </Tiny>
            </PoliciesLink>
            <PoliciesLink to="/terms-of-services">
              <Tiny color={blue50} fontSize="0.75rem">
                Terms and Conditions
              </Tiny>
            </PoliciesLink>
          </FlexWrapper>
        </FlexWrapper>
      </Container>
    </FooterWrapper>
  );
});

FooterDefault.displayName = 'FooterDefault';

export default FooterDefault;

const FooterWrapper = styled.footer`
  padding: 6.25rem 7rem;
  background: ${almostWhite};

  @media ${mobile} {
    padding: 3.625rem 0 5rem;
  }
`;
const ContentWrapper = styled(FlexWrapper)`
  padding: 0 13.5rem 3.5rem 0;
  margin-bottom: 2.625rem;
  border-bottom: 0.0625rem solid ${blue10};

  @media ${mobile} {
    padding: 0 0 2rem;
    margin-bottom: 2rem;

    & > div {
      width: 100%;
    }
  }
`;
const SocialLinksWrapper = styled(FlexWrapper)`
  max-width: 10.875rem;
  width: 100%;

  @media ${mobile} {
    padding-bottom: 2.5rem;
  }
`;
const LinksWrapper = styled.div`
  margin-top: -6rem;
  @media ${mobile} {
    margin-top: 0;
  }
`;
const InputWrapper = styled.div`
  position: relative;
  max-width: 17.625rem;
  width: 100%;
  margin: 2rem 0 3.875rem;
  padding-bottom: 0.375rem;
  border-bottom: 1px solid ${blue30};

  @media ${mobile} {
    margin: 1.25rem 0 2rem;
  }
`;
const Input = styled.input`
  width: 100%;
  border: 0;
  outline: none;
  color: ${blue};
  background: transparent;
  font-size: 1rem;
  font-family: acumin-pro, sans-serif;

  &::placeholder {
    color: ${blue};
    opacity: 0.9;
  }

  &.disabled {
    opacity: 0.5;
  }
`;
const ArrowRightIcon = styled(Svg)`
  position: absolute;
  right: 0;
  bottom: 0.375rem;
  cursor: pointer;
`;
const linkStyles = `
  display: block;
  padding-bottom: 0.875rem;

  &:last-of-type {
    padding-bottom: 0;
  }
`;
const StyledLink = styled(Link)`
  ${linkStyles}
`;
const StyledAnchor = styled.a`
  ${linkStyles}
`;
const PoliciesLink = styled(Link)`
  padding-right: 12%;

  @media ${mobile} {
    padding-right: 0;

    &:last-child {
      margin-top: 0.75rem;
    }
  }
`;
const YearText = styled(Tiny)`
  flex-shrink: 0;
`;
